// --- MODAL --- //
document.body.addEventListener('click', toggleModal);

function toggleModal(el) {
  // Define the element that has triggered the
  var modalTrigger = el.target;

  // Check to see if element CAN open the modal
  if(modalTrigger.classList.contains('modalToggle')) {
    // Get the ID from the element
    var modalId = modalTrigger.getAttribute('href').substr(1);
    // Modal content container
    var modal = document.getElementById(modalId);
    // Check state if it's a close click
    if(modalTrigger.classList.contains('modal__closer')) {
      // Clear all modals
      clearModals();
    } else {
      // Clear all exisiting modals before opening new one
      clearModals();
      // Open targeted modal
      openModal(modal);
    }

    el.preventDefault();

  }

}

// Clear all open modals
function clearModals() {
  document.body.classList.remove('locked');
  var openModals = document.querySelectorAll('.smoker');
  // Fix for older browsers - can't handle a non-Native array :(
  var openModals = Array.prototype.slice.call(openModals);
  openModals.forEach(function(modal){
    modal.classList.remove('on');
  });
}

// Open targeted modal
function openModal(id) {
  id.classList.add('on');
  document.body.classList.add('locked');
}
// --- MODAL END --- //

// Check to see if there is a hash in the URL
if(window.location.hash) {
  // Set var for hash
  var hash = window.location.hash.substring(1); // Puts hash in variable, and removes the # character
    $('#'+ hash + '').toggleClass('on');
    $('body').toggleClass('locked');
} else {
  // No hash found
}

// Overlay Close
$(".small-menu-trigger").click(function(){
    $('body').addClass('navOpen');
    return false;
});

$(".navSmoker").click(function(){
    $('body').removeClass('navOpen');
});

// Form stuff
$('input').focus(function(){
  $(this).removeClass('error');
});

$('.anchor').on('click', function() {

    var scrollAnchor = $(this).attr('data-scroll'),
        scrollPoint = $('section[id="' + scrollAnchor + '"]').offset().top - 50;

    $('body,html').animate({
        scrollTop: scrollPoint
    }, 500);

    $('body').removeClass('navOpen');

    return false;

});

// notification bar function
  var messagetimer;

  function MessageBanner(title, message, type) {

    clearTimeout(messagetimer);
    $('#message-box').hide();
    $('#message-box').removeClass();

    if (type == 'error') {
      $('#message-box').addClass("no");
    } else {
      $('#message-box').addClass("thanks");
    }

    $('#message-box .title').html(title);
    $('#message-box .content').html(message);
    $('#message-box').slideDown(300);

    messagetimer = setTimeout(function() {

      $('#message-box').fadeOut(500);

    }, 8000);

  }

// --- CONTACT FORM --- //
var contactForm = document.getElementById('contactForm');

if(contactForm){
  contactForm.addEventListener('submit', function (event) {
    event.preventDefault();
    document.querySelector('#contactForm .button').setAttribute('disabled', 'disabled');
    grecaptcha.reset();
    grecaptcha.execute();
  });
}

function formSubmit(response) {
  document.getElementById('contactForm').submit();
}
// --- CONTACT FORM END --- //

// Query
var urlParams = new URLSearchParams(window.location.search);
var message = urlParams.get('message');

if(message === 'failed'){
  MessageBanner('Something has gone wrong.', 'Please call us on 0845 644 6593.', 'error');
}
if(message === 'success'){
  MessageBanner('Message sent.', 'Thank you. We will be in touch soon.', 'error');
}

// --- TOASTER END --- //

$(function() {
	$('a[data-rel^=lightcase]').lightcase();
});

$('.quotes').slick({
	infinite: true,
	adaptiveHeight: true,
	arrows: false,
	dots: false,
	autoplay: true,
	fade: true,
	swipe: false,
	touchMove: false,
	autoplaySpeed: 6000,
	speed: 500,
	draggable: false
});

console.log('I ❤ Netlify');